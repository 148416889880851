import Logo from 'assets/images/cameraEnable.svg';
import React, { FC } from 'react';
import constants from 'shared/constants';
import styled from 'styled-components';
import { AppNameINC } from './HeaderINCComponents';

const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const HeaderWrapper = styled.div`
  display: flex;
  background-color: lime;
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
`;

const AppIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${HEADER_HEIGHT}px;
  height: ${HEADER_HEIGHT}px;
  padding: 10px;
`;

const UnAuthenticated: FC = () => {
  return (
    <HeaderWrapper>
      <AppIcon src={Logo} />
      <AppNameINC />
    </HeaderWrapper>
  );
};

export default UnAuthenticated;
