import { Grid, useMediaQuery } from '@material-ui/core';
import { PageHeader } from 'antd';
import Logo from 'assets/images/logo.svg';
import Home from 'assets/images/menu/icon-home.svg';
import Menu from 'assets/images/menu/menu.svg';
import _ from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import constants from 'shared/constants';
import { IReducer } from 'store';
import { getMenu } from 'store/menu/menu.action';
import styled from 'styled-components';

const NAVBAR_WIDTH = constants.NAVBAR_WIDTH;
const NAVBAR_HEIGHT = constants.HEADER_HEIGHT;
const NAVBAR_EXPANDED_WIDTH = constants.NAVBAR_EXPANDED_WIDTH;

interface INavbarBodyProps {
  expand?: boolean;
}

interface IMenuBar {
  link: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  icon: string;
  activeIcon: string;
  label: string;
}

const NavbarItemListWrapper = styled.div`
  margin-top: ${NAVBAR_HEIGHT}px;
  width: 100%;
`;

const NavbarBody = styled.div`
  position: fixed;
  height: 100vh;
  background-color: #2c2e3d;
  overflow-y: hidden;
  overflow-x: hidden;
  &:hover {
    overflow-y: overlay;
  }
`;

const NavbarItemIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${NAVBAR_WIDTH}px;
  height: ${NAVBAR_HEIGHT}px;
`;

const NavbarItem = styled.span`
  position: absolute;
  left: ${NAVBAR_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${NAVBAR_EXPANDED_WIDTH - NAVBAR_WIDTH}px;
  height: ${NAVBAR_HEIGHT}px;
  padding-left: 8px;
  color: white;
`;

const NavbarItemWrapper = styled(Link)<{ isactive?: number }>`
  display: flex;
  width: 100%;
  height: ${NAVBAR_HEIGHT}px;

  ${NavbarItemIcon} {
    padding: 18px; // 22
  }

  &:hover {
    background-color: #404359;
  }
  ${(props) => props.isactive && 'background-color: #404359'};
`;

const NavbarHeader = styled(NavbarItemWrapper)`
  position: fixed;
  width: ${NAVBAR_WIDTH}px;
  background-color: #2c2e3d;
  z-index: 10;
  ${NavbarItemIcon} {
    padding: 10px;
  }
`;

const NavbarHeaderLinkage = styled(NavbarItemWrapper)`
  position: fixed;
  width: ${NAVBAR_WIDTH}px;
  z-index: 10;
  ${NavbarItemIcon} {
    padding: 10px;
  }
  &:hover {
    background-color: #102444;
  }
`;
const NavbarMenu = styled(NavbarHeader)`
  top: ${NAVBAR_HEIGHT}px;
  border-bottom: 1px solid #77716d;
  ${NavbarItemIcon} {
    padding: 22px;
  }
`;

const MenuText = styled.p`
  margin: 0;
  font-size: 24px;
  user-select: none;
`;

const MenuTextHeader = styled(MenuText)`
  font-size: 18px;
`;

const HeaderText = styled(MenuTextHeader)`
  height: 1em;
`;

const HeaderHighlightText = styled(HeaderText)`
  color: #f7b801;
`;

const NavbarWrapper = styled.div<{ expand?: boolean; open?: boolean }>`
  display: flex;
  width: ${(p) => (p.open ? 0 : p.expand ? NAVBAR_EXPANDED_WIDTH : NAVBAR_WIDTH)}px;
  transition: width 0.3s;
  z-index: 10;

  ${NavbarBody} {
    width: ${(p) => (p.open ? 0 : p.expand ? NAVBAR_EXPANDED_WIDTH : NAVBAR_WIDTH)}px;
    transition: width 0.3s;
  }

  ${NavbarItem} {
    pointer-events: ${(p) => (p.expand ? 'all' : 'none')};
  }

  ${MenuText} {
    opacity: ${(p) => (p.expand ? 1 : 0)};
    transition: opacity 0.15s;
  }

  ${NavbarHeader}, ${NavbarMenu} {
    width: ${(p) => (p.open ? 0 : p.expand ? NAVBAR_EXPANDED_WIDTH : NAVBAR_WIDTH)}px;
    transition: width 0.3s;
  }
`;

interface INavbar {
  isLinkage: boolean;
}

const Navbar: FC<INavbar> = (props) => {
  const { isLinkage } = props;
  const { t } = useTranslation('main');
  const history = useHistory();
  const dispatch = useDispatch();

  const [expand, setExpand] = useState(false);
  const HOME_PAGE = '/';
  const DATA_SCIENCE = '/data-science';
  const activeMenu: string =
    history.location.pathname === '/'
      ? HOME_PAGE
      : `/${history.location.pathname.split('/')[1]}` === DATA_SCIENCE
      ? `${history.location.pathname}`
      : `/${history.location.pathname.split('/')[1]}`;

  const menuList = useSelector((o: IReducer) => o.menu.menu);

  useEffect(() => {
    dispatch(getMenu());
  }, [dispatch]);

  const menus: IMenuBar[] = useMemo(() => {
    if (_.some(menuList)) {
      const menuMapping: IMenuBar[] = menuList?.map((menu) => {
        return {
          id: menu?.id || 0,
          link: menu?.url || '/',
          icon: menu?.icon?.filePath || '',
          activeIcon: menu?.activeIcon?.filePath || '',
          label: menu?.label || '',
          onClick: () => setExpand(false),
        };
      });
      return menuMapping;
    } else return [] as IMenuBar[];
  }, [menuList]);

  const issmalldevice = useMediaQuery('only screen and (max-width : 1000px)');
  const [openSearch, setOpenSearch] = React.useState(true);

  React.useEffect(() => {
    if (issmalldevice === true) {
      setOpenSearch(true);
    } else {
      setOpenSearch(false);
    }
  }, [issmalldevice]);

  const onOpenSearch = () => {
    if (openSearch === true) {
      setOpenSearch(false);
    } else {
      setOpenSearch(true);
    }
    // setOpenSearch((prev) => !prev);
  };

  return (
    <>
      {isLinkage !== true ? null : (
        <NavbarHeaderLinkage to={issmalldevice ? '#' : ''} onClick={onOpenSearch}>
          <NavbarItemIcon src={Logo} />
          {/* <NavbarItem>
            <HeaderText>{t('header.royal_thai_police_th.label')}</HeaderText>
            <HeaderText>{t('header.royal_thai_police.label')}</HeaderText>
            <HeaderHighlightText>{t('header.nacrotic_suppression.label')}</HeaderHighlightText>
          </NavbarItem> */}
        </NavbarHeaderLinkage>
      )}

      {isLinkage ? null : (
        <NavbarWrapper expand={expand} open={openSearch}>
          <NavbarBody>
            <NavbarHeader to={issmalldevice ? '#' : ''} onClick={onOpenSearch}>
              <NavbarItemIcon src={Logo} />
              <NavbarItem>
                <HeaderText>{t('header.royal_thai_police_th.label')}</HeaderText>
                <HeaderText>{t('header.royal_thai_police.label')}</HeaderText>
                <HeaderHighlightText>{t('header.nacrotic_suppression.label')}</HeaderHighlightText>
              </NavbarItem>
            </NavbarHeader>

            <NavbarItemListWrapper>
              <NavbarItemWrapper to={'#'} onClick={() => setExpand(!expand)}>
                <NavbarItemIcon src={Menu} />
                <NavbarItem>
                  <MenuText>{t('menu.main_menu', 'เมนู')}</MenuText>
                </NavbarItem>
              </NavbarItemWrapper>
              {issmalldevice === true && (
                <NavbarItemWrapper to={''}>
                  <NavbarItemIcon src={Home} />
                  <NavbarItem>
                    <MenuText>{'หน้าแรก'}</MenuText>
                  </NavbarItem>
                </NavbarItemWrapper>
              )}

              {menus.map((menu, index) => (
                <NavbarItemWrapper
                  to={menu.link}
                  onClick={menu.onClick}
                  key={index}
                  isactive={menu.link === activeMenu ? 1 : 0}
                >
                  <NavbarItemIcon src={menu.link === activeMenu ? menu.activeIcon : menu.icon} />
                  <NavbarItem>
                    <MenuText>{menu.label}</MenuText>
                  </NavbarItem>
                </NavbarItemWrapper>
              ))}
            </NavbarItemListWrapper>
          </NavbarBody>
        </NavbarWrapper>
      )}
    </>
  );
};

export default Navbar;
