import { UnAuthenticatedHeader } from 'components/layout';
import React, { FC, lazy, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IReducer } from 'store';
import { EConfigType, getConfig, LocalStorageKey, verifyToken } from 'store/authenticate';

// eslint-disable-next-line
const LoginPage = lazy(() => import('pages/Auth/Login'));
const LoginSsoPage = lazy(() => import('pages/Auth/Sso/Login'));
const AuthenticatedRouter = lazy(() => import('./Authenticated.router'));

const QRCODE = lazy(() => import('pages/QRCODE'));
const GETCODE = lazy(() => import('pages/QRCODE/components/GETCODE'));
const QRCODEREDIRECT = lazy(() => import('pages/QRCODE/components/QRCODEREDIRECT'));
const INC = lazy(() => import('pages/INC'));

const AppRouter: FC = () => {
  const { t } = useTranslation('main');
  const dispatch = useDispatch();
  const isAuthenticate = useSelector((store: IReducer) => store.authenticate.isAuthenticate);
  const SYSconfig = useSelector((store: IReducer) => store.authenticate.SYSconfig);

  useEffect(() => {
    dispatch(verifyToken());
    dispatch(getConfig('web_login_type'));
  }, [dispatch]);

  const checkExpireToken = (): boolean => {
    const expire = localStorage.getItem(LocalStorageKey.EXPIRES);

    // const expireThaiId = localStorage.getItem(LocalStorageKey.THAI_ID_CODE);
    const expireDate = expire ? new Date(expire) : null;
    // Get the current date and time
    // const currentDate = new Date();

    // // Add one hour to the current date
    // currentDate.setHours(currentDate.getHours() + 1);
    // const expireDateTest = expire ? new Date(currentDate) : null;

    // console.log('Current date + 1 hour:', expireDateTest);

    // if (expireThaiId) return true;
    if (expireDate && expireDate > new Date()) return true;
    else {
      dispatch(verifyToken());
      return false;
    }
  };

  // ;; check thai id
  React.useEffect(() => {
    // if (checkExpireToken()) dispatch(verifyTokenThaiId());
  }, [dispatch]);

  return (
    <>
      {!isAuthenticate ? (
        SYSconfig?.['systemConfigValue'] != EConfigType.WTL01 ? (
          <UnAuthenticatedHeader />
        ) : (
          // <UnAuthenticatedHeaderINC />
          <></>
        )
      ) : (
        <div />
      )}
      <Suspense fallback={<div>{t('router.lazy_loading.label')}</div>}>
        <Switch>
          {/* NOTE: INC */}
          <Route exact path={checkExpireToken() ? '/INC' : '/'} component={INC} />
          {/* NOTE: SSO-LINKAGE */}
          <Route exact path="/GETCODE" component={GETCODE} />
          <Route
            exact
            path="/QRCODEREDIRECT"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <QRCODEREDIRECT />;
            }}
          />
          <Route
            exact
            path="/QRCODE"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else return <QRCODE />;
            }}
          />
          {/* NOTE: DEFAULT */}
          <Route
            exact
            path="/login"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              // else return <LoginPage />;
              else {
                if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL01) return <Redirect to="/QRCODEREDIRECT" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL02) return <Redirect to="/login-sso" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL03) return <LoginPage />;
              }
            }}
          />
          {/* NOTE: SSO */}
          <Route
            exact
            path="/login-sso"
            render={() => {
              if (isAuthenticate === undefined) return dispatch(verifyToken());
              else if (isAuthenticate) return <Redirect to="/" />;
              else {
                if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL01) return <Redirect to="/QRCODEREDIRECT" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL02) return <LoginSsoPage />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL03) return <Redirect to="/login" />;
              }
            }}
          />
          {/* NOTE: DEFAULT */}
          <Route
            render={() => {
              if (isAuthenticate === undefined) {
                dispatch(getConfig('web_login_type'));
                return dispatch(verifyToken());
                // } else if (isAuthenticate && checkExpireToken()) return <Redirect to="/linkage" />;
              } else if (isAuthenticate && checkExpireToken()) return <AuthenticatedRouter />;
              // else return config.SSO ? <Redirect to="/login-sso" /> : <Redirect to="/QRCODEREDIRECT" />;
              else {
                // if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL01) return <Redirect to="/QRCODEREDIRECT" />;
                if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL01) return <Redirect to="/" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL02) return <Redirect to="/login-sso" />;
                else if (SYSconfig?.['systemConfigValue'] == EConfigType.WTL03) return <Redirect to="/login" />;
              }
            }}
          />
        </Switch>
      </Suspense>
      {/* <Footer /> */}
    </>
  );
};

export default AppRouter;
