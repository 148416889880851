import { Checkbox, Grid, IconButton, Menu, MenuItem, useMediaQuery } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import constants from 'shared/constants';
import { EDataTableLayout } from 'shared/enum/data-table.enum';
import { TDataTable } from 'shared/types';
import styled from 'styled-components';
import { TableOrder } from '../constant/table.constant';
import { LargeText } from '../Font';
import { TTableColumn } from '../type';
import TableBody from './TableBody';
import TableContext from './TableContext';
import TableHeader from './TableHeader';
import TableFooter from './TablePagination';

interface ITableProps {
  columns: TTableColumn[];
  dataSource: TDataTable<any>;
  title?: string;
  isLoading: boolean;
  className?: string;
  spaceCount?: number;
  fixCheckbox?: boolean;
  disabled?: boolean | undefined;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onChangeItemsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onHandleChangePage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  onGraph?: any;
  onHeadline?: any;
  onDownload?: any;
  onUpload?: any;
  onFile?: any;
  onEdit?: any;
  onView?: any;
  onDelete?: any;
  onGraphDS?: any;
  onMobile?: any;
  onBank?: any;
  onItem?: number;
  onCheckBox?: any;
  onViewForm?: any;
  onEditForm?: any;
  onModal?: any;
  onSwitch?: any;
  onHistory?: any;
  hidePage?: any;
  hideHeader?: any;
  hideOnView?: boolean;
  hideOnEdit?: boolean;
  hideOnViewForm?: boolean;
  hideOnEditForm?: boolean;
  hideOnDelete?: boolean;
  hideOnExport?: boolean;
  hideOnCheck?: boolean;
  hideOnSwitch?: boolean;
  order?: 'asc' | 'desc' | undefined;
  orderBy?: any;
  setOrder?: any;
  setOrderBy?: any;
  onChangeOrder?: (property: any) => void;
  placeholder?: string;
  onDownloadFileByType?: any;
  stickyPosition?: number;
  responsive?: boolean;
}

const TableWrapper = styled.div`
  display: block;
`;

const StyledTable = styled.div`
  @media (max-width: 544px) {
    overflow: auto;
    /* max-height: 450px;
    width: 100%; */
  }
  /* overflow: auto;
  max-height: 450px;
  width: 100%; */

  /* overflow: auto; */
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableBodyText = styled(LargeText)<{
  overFlow?: boolean;
  width?: string;
  color?: string;
  tooltip?: boolean;
  textaligh?: string;
}>`
  /* display: flex; */
  max-width: ${(props) => (props.width ? props.width : '100ch')};
  width: auto;
  /* กำหนด max-width ตาม props หรือใช้ค่าเริ่มต้น */
  /* min-width: 150px; */
  /* max-width: 100ch; */
  margin: 0;
  white-space: ${(props) => (props.tooltip ? 'nowrap' : 'none')};
  text-overflow: ellipsis;
  ${(props) => (props.width ? `min-width: ${props.width}` : `min-width: ${EDataTableLayout.WIDTHSMALL}`)};
  ${(props) => (props.overFlow ? `max-width: max-content` : ``)};
  ${(props) => (props.overFlow ? `overflow: unset` : `overflow: hidden`)};
  ${(props) => (props.color ? `color: ${props.color};` : '')};
  text-align: ${(props) => (props.textaligh ? `${props.textaligh}` : 'left')};

  margin-left: 5px;
  @media (max-width: 1480px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjust as needed */
`;

const DataTableComponent: FC<ITableProps> = ({
  columns,
  dataSource: data,
  title,
  isLoading,
  className,
  spaceCount,
  fixCheckbox,
  disabled,
  onChangePage,
  onChangeItemsPerPage,
  onHandleChangePage,
  onGraph,
  onHeadline,
  onDownload,
  onUpload,
  onFile,
  onView,
  onEdit,
  onDelete,
  onGraphDS,
  onMobile,
  onBank,
  onItem,
  onCheckBox,
  onViewForm,
  onEditForm,
  onModal,
  onSwitch,
  onHistory,
  hidePage,
  hideHeader,
  hideOnView,
  hideOnEdit,
  hideOnViewForm,
  hideOnEditForm,
  hideOnDelete,
  hideOnExport,
  hideOnCheck,
  hideOnSwitch,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  placeholder,
  onDownloadFileByType,
  stickyPosition,
  responsive,
}) => {
  const [selectedItems] = useState<any>([]);

  const itemsEmptyCount =
    data.pageSize - Math.min(data.pageSize, _.get(data, 'table.length', 0) - (data.currentPage - 1) * data.pageSize);

  const onChangeOrder = (property) => {
    const isAsc = orderBy === property && order === TableOrder.ASCENDING;
    setOrder(isAsc ? TableOrder.DESCENDING : TableOrder.ASCENDING);
    setOrderBy(property);
  };
  const [columnsT, setColumns] = useState<TTableColumn[]>(columns);

  useEffect(() => {
    // ใช้ setColumns เพื่ออัปเดตค่า columnsT
    setColumns(columns);
  }, [columns]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (destination.index === source.index) return;

    const newColumns = Array.from(columnsT);
    const [removed] = newColumns.splice(source.index, 1);
    newColumns.splice(destination.index, 0, removed);

    setColumns(newColumns);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(
    columns.reduce((acc, column) => {
      acc[column.key] = true;
      return acc;
    }, {} as Record<string, boolean>)
  );

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleColumnVisibilityChange = (id: string) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [id]: !prevVisibility[id],
    }));
  };
  const issmalldevice = useMediaQuery('only screen and (max-width : 500px)');

  const mobile = responsive && issmalldevice;

  const columnsWithHeader = columns.filter((column) => column.header === true);

  return (
    <TableWrapper>
      <StyledTable>
        <LargeText>{title}</LargeText>

        <Menu id="column-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuWrapper>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-menu">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {columnsT.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index}>
                        {(provided) => (
                          <MenuItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Checkbox
                              checked={columnVisibility[column.key]}
                              onChange={() => handleColumnVisibilityChange(column.key)}
                              color="primary"
                            />
                            {column.title}
                          </MenuItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </MenuWrapper>
        </Menu>

        <DragDropContext onDragEnd={onDragEnd}>
          <TableContext.Provider
            value={{
              title: title,
              columns: columnsT.filter((column) => columnVisibility[column.key]), // Filter columns based on visibility
              page: data.currentPage - 1,
              items: _.get(data, 'table', []),
              isLoading,
              itemsCount: _.get(data, 'table.length', 0),
              totalCount: data.totalCount,
              itemsEmptyCount: itemsEmptyCount,
              itemsPerPage: data.pageSize,
              selectedItems: selectedItems,
              selectedItemsCount: _.get(selectedItems, 'length', 0),
              fixCheckbox: fixCheckbox,
              disabled: disabled,
              onChangePage: onChangePage,
              onChangeItemsPerPage: onChangeItemsPerPage,
              onHandleChangePage: onHandleChangePage,
              onGraph: onGraph,
              onHeadline: onHeadline,
              onDownload: onDownload,
              onUpload: onUpload,
              onFile: onFile,
              onView: onView,
              onEdit: onEdit,
              onDelete: onDelete,
              spaceCount: spaceCount,
              onGraphDS: onGraphDS,
              onMobile: onMobile,
              onBank: onBank,
              onItem: onItem,
              onCheckBox: onCheckBox,
              onViewForm: onViewForm,
              onEditForm: onEditForm,
              onModal: onModal,
              onSwitch: onSwitch,
              onHistory: onHistory,
              hidePage: hidePage,
              hideHeader: hideHeader,
              hideOnView: hideOnView,
              hideOnEdit: hideOnEdit,
              hideOnViewForm: hideOnViewForm,
              hideOnEditForm: hideOnEditForm,
              hideOnDelete: hideOnDelete,
              hideOnExport: hideOnExport,
              hideOnCheck: hideOnCheck,
              hideOnSwitch: hideOnSwitch,
              order: order,
              orderBy: orderBy,
              setOrder: setOrder,
              setOrderBy: setOrderBy,
              onChangeOrder: onChangeOrder,
              placeholder: placeholder,
              onDownloadFileByType: onDownloadFileByType,
              stickyPosition: stickyPosition,
              handleMenuClick: handleMenuClick,
            }}
          >
            {!mobile && (
              <Table className={className}>
                <TableHeader />
                <TableBody />
              </Table>
            )}

            {mobile && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                {data.table.length <= 0 ? (
                  <div
                    style={{
                      border: '1px solid #ccc',
                      padding: '8px',
                    }}
                  >
                    <TableBodyText color="red">ไม่พบข้อมูล</TableBodyText>
                  </div>
                ) : (
                  data.table.map((row, rowIndex) => (
                    <div key={rowIndex} style={{ backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#f0efef' }}>
                      {columns.map((column) => (
                        <div
                          key={column.key}
                          style={{
                            border: '1px solid #ccc',
                            padding: '8px',
                            backgroundColor: column.key === 'index' ? `${constants.LIGHT_BLUE}` : 'transparent',
                          }}
                        >
                          <FlexGrid>
                            <TableBodyText width={column.width} tooltip={column.tooltip}>
                              {column.title}: {column.key === 'index' ? row[column.key] : null}
                            </TableBodyText>
                            <TableBodyText width={column.width} tooltip={column.tooltip} textaligh="right">
                              {column.key === 'index' ? row[columnsWithHeader[0]?.key] : row[column.key]}
                            </TableBodyText>
                          </FlexGrid>
                        </div>
                      ))}
                    </div>
                  ))
                )}
                <br />
              </div>
            )}
          </TableContext.Provider>
        </DragDropContext>
      </StyledTable>

      <TableContext.Provider
        value={{
          title: title,
          columns: columns,
          page: data.currentPage - 1,
          items: _.get(data, 'table', []),
          isLoading,
          itemsCount: _.get(data, 'table.length', 0),
          totalCount: data.totalCount,
          itemsEmptyCount: itemsEmptyCount,
          itemsPerPage: data.pageSize,
          selectedItems: selectedItems,
          selectedItemsCount: _.get(selectedItems, 'length', 0),
          fixCheckbox: fixCheckbox,
          disabled: disabled,
          onChangePage: onChangePage,
          onChangeItemsPerPage: onChangeItemsPerPage,
          onHandleChangePage: onHandleChangePage,
          onGraph: onGraph,
          onHeadline: onHeadline,
          onDownload: onDownload,
          onUpload: onUpload,
          onFile: onFile,
          onView: onView,
          onEdit: onEdit,
          onDelete: onDelete,
          spaceCount: spaceCount,
          onGraphDS: onGraphDS,
          onMobile: onMobile,
          onBank: onBank,
          onItem: onItem,
          onCheckBox: onCheckBox,
          onViewForm: onViewForm,
          onEditForm: onEditForm,
          onModal: onModal,
          onSwitch: onSwitch,
          onHistory: onHistory,
          hidePage: hidePage,
          hideHeader: hideHeader,
          hideOnView: hideOnView,
          hideOnEdit: hideOnEdit,
          hideOnViewForm: hideOnViewForm,
          hideOnEditForm: hideOnEditForm,
          hideOnDelete: hideOnDelete,
          hideOnExport: hideOnExport,
          hideOnCheck: hideOnCheck,
          hideOnSwitch: hideOnSwitch,
          order: order,
          orderBy: orderBy,
          setOrder: setOrder,
          setOrderBy: setOrderBy,
          onChangeOrder: onChangeOrder,
          placeholder: placeholder,
          onDownloadFileByType: onDownloadFileByType,
          stickyPosition: stickyPosition,
        }}
      >
        {!isLoading && <TableFooter />}
      </TableContext.Provider>
    </TableWrapper>
  );
};

export default DataTableComponent;
