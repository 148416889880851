import Logo from 'assets/images/logo.svg';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom'; // Import the useLocation hook
import constants from 'shared/constants';
import styled from 'styled-components';
import { AppName } from './HeaderComponents';

const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const HeaderWrapper = styled.div`
  display: flex;
  background-color: #0b2545;
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
`;

const AppIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${HEADER_HEIGHT}px;
  height: ${HEADER_HEIGHT}px;
  padding: 10px;
`;

const UnAuthenticatedINC: FC = () => {
  const location = useLocation(); // Use useLocation to get the current path

  // Check if the path is not /INC and render the component conditionally
  if (location.pathname === '/INC') {
    return <></>; // Return an empty fragment if the current path is /INC
  }

  return (
    <HeaderWrapper>
      <AppIcon src={Logo} />
      <AppName />
    </HeaderWrapper>
  );
};

export default UnAuthenticatedINC;
